import React, { Component } from 'react';
import ContactPerson from './ContactPerson.component';
import Language from './Language.data';
import styled from 'styled-components';
import externalLinkImage from '../images/externalLink.svg';
import { isNullOrUndefined, replaceSystemNewLineCharactersWithHtml } from '../utils/utils';
require('bootstrap');

const ProductGroupContainer = styled.div`
    @media (max-width: 576px) { 
        padding-left:0px;
        padding-right:0px;
    }
` 
const ContactCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

class ProductGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }  

    createMarkup(htmlString) {
        htmlString = htmlString.replaceAll('®','<sup>®</sup>');
        htmlString = htmlString.replaceAll('TM','<sup>TM</sup>');
        return {__html: replaceSystemNewLineCharactersWithHtml(htmlString)};
    }

    handleProductGroupClick(event) {
        event.stopPropagation();
        this.props.onProductGroupClick(this.props.productGroup);
    }

    render() {
        let hasHomepage = (this.props.productGroup.supplierWebsite.trim().length > 0 && this.props.productGroup.supplierWebsite !== 'http://www.grolman-group.com') ? true : false;
        return (
            <ProductGroupContainer className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center borderBottom1pxSolid888">
                        
                        <div className={"card-body"}>
                            <h6 className="card-title cursorPointer" onClick={(event) => this.handleProductGroupClick(event)}> 
                                {this.props.productGroup.productGroupName}
                            </h6>
                            {
                                this.props.displayProductGroupDetails &&
                                <div className="borderRadius10px border1pxSolide0e0e0 padding10px">
                                    <div className="d-block d-md-none">
                                        <div className="row">
                                            <div className="col-12">
                                                <strong>{Language[this.props.currentLanguage].producer}</strong>
                                            </div> 
                                            <div className="col-12">
                                                {
                                                    hasHomepage && parseInt(this.props.productGroup.productGroupID) === 2061 &&
                                                    <div>
                                                        <a href={"https://www.momentive.com/en-us/categories/silicone-pressure-sensitive-adhesives"} className="link" target="_blank">{this.props.productGroup.supplierName}</a>
                                                        <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                    </div>
                                                }
                                                {
                                                    hasHomepage && parseInt(this.props.productGroup.productGroupID) !== 2061 &&
                                                    <div>
                                                        <a href={(this.props.productGroup.supplierWebsite.startsWith("http")) ? this.props.productGroup.supplierWebsite : "http://" + this.props.productGroup.supplierWebsite} className="link" target="_blank">{this.props.productGroup.supplierName}</a>
                                                        <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                    </div>
                                                }
                                                {
                                                    !hasHomepage &&
                                                    this.props.productGroup.supplierName
                                                }
                                            </div> 
                                        </div>
                                        {
                                            // More Info Link nur anzeigen wenn Lieferant "Elementis" & WOGs 2140,2141,2142 und BU "Additive"
                                            (this.props.productGroup.supplierID === "22724" && this.props.mainProductGroup.buId === "1" && ['2140','2141','2142'].includes(this.props.productGroup.productGroupID)) &&
                                            <div className="row">
                                                <div className="col-12">
                                                    <a href="https://web2.grolman-group.com/elementis-additifs/" className="" rel="noopener noreferrer" target="_blank">{Language[this.props.currentLanguage].moreInfo}</a>
                                                    <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                </div> 
                                            </div>
                                        }
                                        { 
                                            // More Info Link nur anzeigen wenn Lieferant "Sapici" und BU "Binders"
                                            (this.props.productGroup.supplierID === "22012" && this.props.mainProductGroup.buId === "2") &&
                                            <div className="row">
                                                <div className="col-12">
                                                    <a href="https://web2.grolman-group.com/sapici-laminating-adhesives/" className="" rel="noopener noreferrer" target="_blank">{Language[this.props.currentLanguage].moreInfo}</a>
                                                    <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                </div> 
                                            </div>
                                        }
                                        <div className="row marginTop25px">
                                            <div className="col-12">
                                                <strong>{Language[this.props.currentLanguage].tradeName}</strong>
                                            </div> 
                                            <div className="col-12">
                                                <div dangerouslySetInnerHTML={this.createMarkup(this.props.productGroup.productGroupTradeName)} />
                                            </div> 
                                        </div>
                                        <div className="row marginTop25px">
                                            <div className="col-12">
                                                <strong>{Language[this.props.currentLanguage].description}</strong>
                                            </div> 
                                            <div className="col-12">
                                                <div dangerouslySetInnerHTML={this.createMarkup(this.props.productGroup.productGroupDescription)} />
                                            </div> 
                                        </div>
                                        {
                                            this.props.productGroup.applications?.length > 0 &&
                                            <div className="row marginTop25px">
                                                <div className="col-12">
                                                        <strong>{Language[this.props.currentLanguage].applications}</strong>
                                                </div>
                                                <div className="col-12">
                                                    {
                                                        this.props.productGroup.applications?.map((application) => {
                                                            let cssClass = "";
                                                            return (
                                                                <div key={"productGroup" + this.props.productGroup.productGroupID + "Application" + application.label} className={cssClass}>
                                                                    {application.label.replace(application.mainBranchLabel + ' - ','')}
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !isNullOrUndefined(this.props.productGroup.contact) && 
                                            <ContactCard>
                                                <ContactPerson 
                                                    contact={this.props.productGroup.contact} 
                                                    key={"contact" + this.props.productGroup.productGroupID} 
                                                    imagePosition={"center"}
                                                    imageSize={"140px"}
                                                />
                                            </ContactCard>
                                        }
                                    </div>
                                    <div className="d-none d-md-block">
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                <strong>{Language[this.props.currentLanguage].producer}</strong>
                                            </div> 
                                            <div className="col-6 text-left">
                                                {
                                                    hasHomepage && parseInt(this.props.productGroup.productGroupID) === 2061 &&
                                                    <div>
                                                        <a href={"https://www.momentive.com/en-us/categories/silicone-pressure-sensitive-adhesives"} className="link" target="_blank">{this.props.productGroup.supplierName}</a>
                                                        <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                    </div>
                                                }
                                                {
                                                    hasHomepage && parseInt(this.props.productGroup.productGroupID) !== 2061 &&
                                                    <div>
                                                        <a href={(this.props.productGroup.supplierWebsite.startsWith("http")) ? this.props.productGroup.supplierWebsite : "http://" + this.props.productGroup.supplierWebsite} className="link" target="_blank">{this.props.productGroup.supplierName}</a>
                                                        <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                    </div>
                                                }
                                                {
                                                    !hasHomepage &&
                                                    this.props.productGroup.supplierName
                                                }
                                            </div> 
                                        </div>
                                        {
                                            /*
                                            // More Info Link nur anzeigen wenn WOG 737
                                            (this.props.productGroup.productGroupID === "737") &&
                                            <div className="row">
                                                <div className="col-6">
                                                </div> 
                                                <div className="col-6 text-left">
                                                    <a href="https://google.de/" className="" rel="noopener noreferrer" target="_blank">{Language[this.props.currentLanguage].moreInfo}</a>
                                                    <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                </div> 
                                            </div>
                                            */
                                        }
                                        {
                                            // More Info Link nur anzeigen wenn Lieferant "Elementis" & WOGs 2140,2141,2142 und BU "Additive"
                                            (this.props.productGroup.supplierID === "22724" && this.props.mainProductGroup.buId === "1" && ['2140','2141','2142'].includes(this.props.productGroup.productGroupID)) &&
                                            <div className="row">
                                                <div className="col-6">
                                                </div> 
                                                <div className="col-6 text-left">
                                                    <a href="https://web2.grolman-group.com/elementis-additifs/" className="" rel="noopener noreferrer" target="_blank">{Language[this.props.currentLanguage].moreInfo}</a>
                                                    <img src={externalLinkImage} alt="External Link" className="externalLinkImage"></img>
                                                </div> 
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                <strong>{Language[this.props.currentLanguage].tradeName}</strong>
                                            </div> 
                                            <div className="col-6 text-left">
                                                <div dangerouslySetInnerHTML={this.createMarkup(this.props.productGroup.productGroupTradeName)} />
                                            </div> 
                                        </div>
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                <strong>{Language[this.props.currentLanguage].description}</strong>
                                            </div> 
                                            <div className="col-6 text-left">
                                                <div dangerouslySetInnerHTML={this.createMarkup(this.props.productGroup.productGroupDescription)} />
                                            </div> 
                                        </div>
                                        {
                                            this.props.productGroup.applications?.length > 0 &&
                                            <div className="row">
                                                <div className="col-6 text-right">
                                                        <strong>{Language[this.props.currentLanguage].applications}</strong>
                                                </div>
                                                <div className="col-6 text-left">
                                                    {
                                                        this.props.productGroup.applications?.map((application) => {
                                                            let cssClass = "";
                                                            return (
                                                                <div key={"productGroup" + this.props.productGroup.productGroupID + "Application" + application.label} className={cssClass}>
                                                                    {application.label.replace(application.mainBranchLabel + ' - ','')}
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !isNullOrUndefined(this.props.productGroup.contact) && 
                                            <div className="row">
                                                <div className="col-6 text-right marginTop25px">
                                                    <strong>{Language[this.props.currentLanguage].contact}</strong>
                                                </div>
                                                <div className="col-6 text-left">
                                                    <ContactPerson 
                                                        contact={this.props.productGroup.contact}
                                                        key={"contact" + this.props.productGroup.productGroupID} 
                                                        imagePosition={"start"}
                                                        imageSize={"140px"}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ProductGroupContainer>
        );
    }
}

export default ProductGroup;