import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as Utils from '../utils/utils';
import Slider from "react-slick";
import ExternalLink from './ExternalLink.component';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//const products = React.lazy(() => import('../images/carousel/_Produkte.png'));

const products = require('../images/carousel/_Produkte.webp').default;
const industries = require('../images/carousel/_Industrien.webp').default;
const elementis = require('../images/carousel/_Elementis.webp').default;
const personalCare = require('../images/carousel/_PersonalCare.webp').default;
const dcdCoatings = require('../images/carousel/ThankYouForJoiningRecoatOurPlanet.webp').default;
const momentive = require('../images/carousel/momentive.webp').default;
const vision = require('../images/carousel/vision_sustainability.webp').default;
const kShow = require('../images/carousel/KShow.webp').default;
const podcast = require('../images/carousel/podcast.webp').default;
const incos2023Poster = require('../images/videos/incos2023poster.webp').default;
const incos2023Video = require('../videos/incos2023.mp4').default;
const codeOfConduct = require('../images/carousel/CodeOfConduct.webp').default;
const ecovadis = require('../videos/ecovadis.mp4').default;
const alucha = require('../images/carousel/alucha.gif').default;

const Video = styled.video`
    max-width:1100px;
    max-height:740px;
    width:100%;
`

const CarouselVideoCaption = styled.div`
    position: relative !important;
    right: auto !important;
    left: auto !important;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    bottom:20px;
`

import externalLinkImage from '../images/externalLink.svg';

function StartPageSlider(props) {
    const [carouselImages, setCarouselImages] = useState([]);
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        pauseOnFocus: true,
        className: 'sliderButtons',
        autoplay: false,
        autoplaySpeed: 4000,
    };

    useEffect(() => {

        let images = [
            {
                type: 'image',
                image: alucha,
                text: null,
                optionalClasses: '',
                alt: 'Alucha',
                firstImage: true,
                link: {
                    type: 'external',
                    url: 'https://www.alucha.com/projects/life-re-uce/',
                    callback: undefined
                }
            },
            {
                type: 'video',
                video: ecovadis,
                poster: undefined,
                autoplay: true,
                muted: true,
                loop: true,
                preload: 'auto',
                controls: undefined,
                text: null,
                optionalClasses: '',
                firstImage: false,
            },
            {
                type: 'video',
                video: incos2023Video,
                poster: incos2023Poster,
                autoplay: undefined,
                muted: undefined,
                loop: undefined,
                preload: 'none',
                controls: true,
                text: 'Thanks for a beautiful in-cosmetics 2023!',
                optionalClasses: '',
                firstImage: false,
            },
            {
                type: 'image',
                image: codeOfConduct,
                text: 'Click here for more information on our Code of Conduct',
                optionalClasses: '',
                alt: 'Code of Conduct',
                firstImage: false,
                link: {
                    type: 'internal',
                    url: '/resources',
                    callback: Utils.scrollToTop
                }
            },
            {
                type: 'image',
                image: podcast,
                text: 'More information',
                optionalClasses: '',
                alt: 'Podcast',
                firstImage: false,
                link: {
                    type: 'internal',
                    url: '/podcast',
                    callback: Utils.scrollToTop
                }
            },
            {
                type: 'image',
                image: vision,
                text: <><a href="https://grolman.earth/" target="_blank" rel="noopener noreferrer">Click here for more information</a> <img src={externalLinkImage} alt="External Link" className="externalLinkImage" style={{width:'15px',height:'15px',display:'inline-block'}}></img></>,
                optionalClasses: '',
                alt: 'Vision & Sustainability',
                firstImage: false,
                link: {
                    type: 'external',
                    url: 'https://grolman.earth',
                    callback: undefined
                }
            },
            {
                type: 'image',
                image: kShow,
                text: null,
                optionalClasses: '',
                alt: 'K 2022',
                firstImage: false,
            },
            {
                type: 'image',
                image: dcdCoatings,
                text: 'Thank you for joining Recoat our Planet',
                optionalClasses: '',
                alt: 'Thank you for joining Recoat our Planet',
                firstImage: false,
                link: {
                    type: 'external',
                    url: 'https://registration.socio.events/e/recoatourplanet/promo-codes/RECOATOURPLANET',
                    callback: undefined
                }
            },
            {
                type: 'image',
                image: momentive,
                text: 'More information',
                optionalClasses: '',
                alt: 'Momentive',
                firstImage: false,
                link: {
                    type: 'external',
                    url: 'https://web2.grolman-group.com/momentive-additives/',
                    callback: undefined
                }
            },
            {
                type: 'image',
                image: elementis,
                text: 'More information',
                optionalClasses: '',
                alt: 'Elementis',
                firstImage: false,
                link: {
                    type: 'external',
                    url: 'https://web2.grolman-group.com/elementis-additifs',
                    callback: undefined
                },
            },
            {
                type: 'image',
                image: products,
                text: null,
                optionalClasses: 'backgroundTransparent',
                alt: 'Products',
                firstImage: false,
                link: {
                    type: 'internal',
                    url: '/products',
                    callback: Utils.scrollToTop
                },
            },
            {
                type: 'image',
                image: industries,
                text: null,
                optionalClasses: 'backgroundTransparent',
                alt: 'Industries',
                firstImage: false,
                link: {
                    type: 'internal',
                    url: '/industries',
                    callback: Utils.scrollToTop
                },
            },
            {
                type: 'image',
                image: personalCare,
                text: 'Grolman Personal Care', //'<a href="https://grolman-personalcare.com/" target="_blank" rel="noopener noreferrer">Grolman Personal Care</a>', // <img src="' + externalLinkImage + '" alt="External Link" className="externalLinkImage" style="width:20px;height:20px;"></img>
                optionalClasses: '',
                alt: 'Grolman Personal Care',
                firstImage: false,
                link: {
                    type: 'external',
                    url: 'https://grolman-personalcare.com/',
                    callback: undefined
                },
            }
        ];

        setCarouselImages(images);
    },[]);


    return (
        <div id="slider">
            <div className="container-fluid marginBottom50px area">
                <div className="row marginTop25px">
                    <div className="col-12 text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <Slider {...sliderSettings}>
                                        {
                                            carouselImages.map((image,index) => {
                                                return(
                                                    <div key={"carouselImageDiv" + index}>
                                                        {
                                                            image.type === "video" &&
                                                            <div>
                                                                <Video 
                                                                    autoPlay={image.autoplay}
                                                                    muted={image.muted}
                                                                    loop={image.loop}
                                                                    controls={image.controls} 
                                                                    preload={image.preload} 
                                                                    poster={image.poster}
                                                                >
                                                                    <source 
                                                                        src={image.video}
                                                                        type="video/mp4" 
                                                                    />
                                                                    Your browser does not support the video tag.
                                                                </Video>
                                                                {
                                                                    !Utils.isNullOrUndefined(image.text) &&
                                                                    <CarouselVideoCaption className="d-none d-md-block">
                                                                        <div className={"carouselText " + image.optionalClasses} dangerouslySetInnerHTML={{__html: image.text}}></div>
                                                                    </CarouselVideoCaption>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            image.type === "image" && image.link !== undefined && image.link.type === 'internal' &&
                                                            <div>
                                                                <Link 
                                                                    to={image.link.url} 
                                                                    key={"carouselImage" + index} 
                                                                    onClick={image.link.callback}
                                                                > 
                                                                    <img 
                                                                        className="d-block w-100" 
                                                                        src={image.image} 
                                                                        alt={image.alt}
                                                                        loading="lazy"
                                                                    />
                                                                    {
                                                                        image.text !== null &&
                                                                        <div className="carousel-caption d-none d-md-block">
                                                                            <div className={"carouselText " + image.optionalClasses} dangerouslySetInnerHTML={{__html: image.text}}></div>
                                                                        </div>
                                                                    }
                                                                </Link>
                                                            </div>
                                                        }
                                                        {
                                                            image.type === "image" && image.link !== undefined && image.link.type === 'external' &&
                                                            <div>
                                                                <ExternalLink
                                                                    {...props}
                                                                    key={"carouselImage" + index} 
                                                                    id={image.key}
                                                                    linkHrefTag={image.link.url}
                                                                    linkTargetTag={"_blank"}
                                                                    linkAdditionalClasses={"d-block w-100"}
                                                                    imageSrcTag={image.image}
                                                                    imageAltTag={image.alt}
                                                                    imageWidth={"100%"}
                                                                    imageMinWidth={"100%"}
                                                                    iconWidth={"30px"}
                                                                    iconHeight={"30px"}
                                                                    iconPositonTop={"50%"}
                                                                    iconPositonLeft={"50%"}
                                                                    iconMarginLeft={"-15px"}
                                                                    iconMarginTop={"-15px"}
                                                                    showExternalLinkText={true}
                                                                />
                                                                {
                                                                    image.text !== null &&
                                                                    <div className="carousel-caption d-none d-md-block">
                                                                        <div className={"carouselText " + image.optionalClasses}>{image.text}</div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            image.type === 'image' && image.link === undefined &&
                                                            <div>
                                                                <img className="d-block w-100" src={image.image} alt={image.alt} />
                                                                {
                                                                    image.text !== null &&
                                                                    <div className="carousel-caption d-none d-md-block">
                                                                        <div className={"carouselText " + image.optionalClasses} dangerouslySetInnerHTML={{__html: image.text}}></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StartPageSlider;