import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StyledLink from './Link.component';
import Language from './Language.data.js';
import styled from 'styled-components';
import * as Tracking from '../utils/matomo';
require('bootstrap');

import cert from '../images/verified_user-24px.svg';
import pdfIcon from '../images/pdf.svg'

const CardHeader = styled.div`
    background-color: #18657a !important;
    color: #fff !important;
`

const Cert = styled.img`
    height:35px;
`

const DocumentDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};

    @media (max-width: 320px) { 
        justify-content: center;
        align-items: center;
    }
`

const PDFImage = styled.img`
    height:35px;
`

/*
const StyledLink = styled.a`
    color:#000;
    text-decoration:underline;

    &:hover {
        color:#000;
    }
`
*/

class Downloads extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }   

    onLinkClick(event,clickType) {
        Tracking.trackEvent('General','Click Resources ' + clickType, 'Resources ' + clickType);
    }

    render() {
        return (
            <div className="container marginTop50px marginBottom50px area" id="jobs">
                <div className="row marginTop50px">
                    <div className="col-12 text-center">
                        <h2>{Language[this.props.currentLanguage].resources}</h2>
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#codeOfConduct" aria-expanded="false" aria-controls="codeOfConduct">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].codeOfConduct}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="codeOfConduct">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].codeOfConductSentence}
                                    </div>
                                    <DocumentDiv 
                                        justifyContent="flex-end" 
                                        alignItems="flex-end"
                                        className="col text-center"
                                    >
                                        <div>
                                            <a href={"documents/GrolmanGroupCodeOfConduct_November2022.pdf"} target="_blank">
                                                <PDFImage src={pdfIcon} alt="" />
                                            </a>
                                            <br />
                                            <StyledLink 
                                                linkUrl={"documents/GrolmanGroupCodeOfConduct_November2022.pdf"} 
                                                linkText={Language[this.props.currentLanguage].codeOfConduct}
                                                isExternalLink={false}
                                                target="_blank"
                                                color="#000"
                                            />
                                        </div>
                                    </DocumentDiv>
                                    <DocumentDiv
                                        justifyContent="flex-start" 
                                        alignItems="flex-start"
                                        className="col text-center"
                                    >
                                        <div>
                                            <a href={"documents/ComplianceForm.pdf"} target="_blank">
                                                <PDFImage src={pdfIcon} alt="" />
                                            </a>
                                            <br />
                                            <StyledLink 
                                                linkUrl={"documents/ComplianceForm.pdf"} 
                                                linkText={Language[this.props.currentLanguage].complianceForm}
                                                isExternalLink={false}
                                                target="_blank"
                                                color="#000"
                                            />
                                        </div>
                                    </DocumentDiv>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#sustainabilityStatement" aria-expanded="false" aria-controls="sustainabilityStatement">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].sustainabilityStatement}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="sustainabilityStatement">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].sustainabilityStatementSentence}
                                    </div>
                                    <DocumentDiv 
                                        justifyContent="center" 
                                        alignItems="center"
                                        className="col text-center"
                                    >
                                        <div>
                                            <a href={"documents/Sustainability_Statement_Grolman_for_Business_Partners.pdf"} target="_blank">
                                                <PDFImage src={pdfIcon} alt="" />
                                            </a>
                                            <br />
                                            <StyledLink 
                                                linkUrl={"documents/Sustainability_Statement_Grolman_for_Business_Partners.pdf"} 
                                                linkText={Language[this.props.currentLanguage].sustainabilityStatement}
                                                isExternalLink={false}
                                                target="_blank"
                                                color="#000"
                                            />
                                        </div>
                                    </DocumentDiv>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#sustainabilityReport" aria-expanded="false" aria-controls="sustainabilityReport">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    { `${Language[this.props.currentLanguage].sustainabilityReport} 2022` }
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="sustainabilityReport">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].sustainabilityReportSentence}
                                    </div>
                                    <DocumentDiv
                                        justifyContent="flex-center" 
                                        alignItems="flex-center"
                                        className="col text-center"
                                    >
                                        <div>
                                            <a href="/documents/GrolmanInternationalDistributionSustainabilityReport2022.pdf" target="_blank">
                                                <PDFImage src={pdfIcon} alt="" />
                                            </a>
                                            <br />
                                            <StyledLink 
                                                linkUrl={"/documents/GrolmanInternationalDistributionSustainabilityReport2022.pdf"} 
                                                linkText={`${Language[this.props.currentLanguage].sustainabilityReport} 2022`}
                                                isExternalLink={false}
                                                target="_blank"
                                                color="#000"
                                            />
                                        </div>
                                    </DocumentDiv>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#termsAndConditions" aria-expanded="false" aria-controls="termsAndConditions">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].termsAndConditions}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="termsAndConditions">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        <Link to="/termsandconditions" className="cursorPointer resourcesLink" onClick={(e) => this.onLinkClick(e,'Terms & Conditions')}>{ Language[this.props.currentLanguage].termsAndConditions }</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#msdsDownloads" aria-expanded="false" aria-controls="msdsDownloads">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].msds}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="msdsDownloads">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].msdsDownloadSentence}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        <StyledLink 
                                            color="#000"
                                            linkUrl="https://download.grolman-group.com" 
                                            linkText="https://download.grolman-group.com" 
                                            isExternalLink={true}
                                            target="_self"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#stockAppointmentBooking" aria-expanded="false" aria-controls="stockAppointmentBooking">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].stockAppointmentBooking}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="stockAppointmentBooking">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].stockAppointmentBookingSentence}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        <StyledLink 
                                            color="#000"
                                            linkUrl="https://warehouse.grolman-group.com" 
                                            linkText="https://warehouse.grolman-group.com" 
                                            isExternalLink={true}
                                            target="_self"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#rspoMembership" aria-expanded="false" aria-controls="rspoMembership">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].rspoMembership}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="rspoMembership">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].rspoMembershipSentence}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>  

                <div className="row align-items-center h-100 marginBottom50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#emailCertificates" aria-expanded="false" aria-controls="emailCertificates">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].emailCertificates}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="emailCertificates">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].emailCertificatesSentence}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        <a href="https://grolman-group.com/cert/gg_securemail.crt" target="_self">
                                            <Cert src={cert} alt="Certificate download" />
                                        </a> 
                                        <StyledLink 
                                            color="#000"
                                            linkUrl="https://grolman-group.com/cert/gg_securemail.crt" 
                                            linkText={Language[this.props.currentLanguage].certificate}
                                            isExternalLink={false}
                                            target="_self"
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].fingerprint + " (SHA-1): 0A:3B:66:4B:9C:AC:70:4B:DE:64:92:19:98:CC:BA:B4:EB:5A:F3:52"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center h-100 marginBottom50px marginTop50px">
                    <div className="offset-1 marginTop50px">
                    </div>
                    <div className="col-10 text-center justify-content-center">
                        <div className="card">
                            <CardHeader className="card-header" data-toggle="collapse" data-target="#whistleblowersystem" aria-expanded="false" aria-controls="whistleblowersystem">
                                <h5 className="card-title cursorPointer mainProductGroupTitle">
                                    {Language[this.props.currentLanguage].whistleblower}
                                </h5>
                            </CardHeader>
                            <div className="card-body borderBottom1pxSolid888 show" id="whistleblowersystem">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center font-weight-bold">
                                        {Language[this.props.currentLanguage].introduction}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].whistleblowerIntroductionSentence}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center mt-3 font-weight-bold">
                                        {Language[this.props.currentLanguage].purpose}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].whistleblowerPurposeSentence}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center mt-3 font-weight-bold">
                                        {Language[this.props.currentLanguage].actionsAndResponsibilities}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center">
                                        {Language[this.props.currentLanguage].whistleblowerActionsAndResponsibilitiesSentence} 
                                        <StyledLink 
                                            color="#000"
                                            linkUrl="https://www.grantthornton.de" 
                                            linkText="www.grantthornton.de"
                                            isExternalLink={true}
                                            target="_blank" 
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center justify-content-center mt-3">
                                        { `${Language[this.props.currentLanguage].whistleblower}: ` }
                                        <StyledLink 
                                            color="#000"
                                            linkUrl="https://grolman-group.gt-wbs.com" 
                                            linkText="https://grolman-group.gt-wbs.com" 
                                            isExternalLink={true}
                                            target="_blank"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 marginTop50px">
                    </div>
                </div>


            </div>
        );
    }
}

export default Downloads;